import { centreClient } from './client'

export const microserviceRequests = {
  getUserNotificationConfig: async (userId: string) => {
    const response = await centreClient
      .get(`api/internal/microservices/user-notification-config/${userId}`)
      .json<{
        data: UserNotificationConfig
      }>()
    return response.data
  },

  updateUserNotificationConfig: async ({
    id,
    esign_completed,
  }: UpdateUserNotificationConfigRequest) => {
    return centreClient.post(`api/internal/microservices/user-notification-config/${id}`, {
      json: {
        esign_completed,
      },
    })
  },
}

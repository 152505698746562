import { useQuery } from '@tanstack/react-query'
import { aggregatorService } from 'src/api'
import { customPagesQueryKeys } from './customPagesQueryKey'

export default function useGetStaffCustomPageById(id?: string) {
  return useQuery({
    queryKey: customPagesQueryKeys.getByIdStaff(id),
    queryFn: () =>
      id
        ? aggregatorService.getStaffCustomPageById(id)
        : Promise.reject(new Error('No id provided')),
    select: (data) => data.data,
    enabled: !!id,
    meta: {
      errorMessage: 'Unable to load. Please Try again',
    },
  })
}

import { FormProvider } from 'react-hook-form'
import { Card, CardBody, SimpleGrid, Stack } from 'src/components/designsystem'
import ContentSection from './ContentSection'
import FormHeader from './FormHeader'
import FilesSection from './FilesSection'
import SetupSection from './SetupSection'
import useStaffCustomPageForm from './useStaffCustomPageForm'

export default function CustomPageForm({
  customPageDetail,
}: Readonly<{ customPageDetail?: StaffCustomPageDetails }>) {
  const { form, isPublished, onSave } = useStaffCustomPageForm(customPageDetail)

  return (
    <FormProvider {...form}>
      <form noValidate onSubmit={form.handleSubmit(onSave)}>
        <Card>
          <FormHeader isEditing={!!customPageDetail?.id} isPublished={isPublished} />
          <CardBody py={6} px={{ base: 4, sm: 6 }}>
            <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={4}>
              <Stack gap={6}>
                <SetupSection />
                <ContentSection />
              </Stack>
              <Stack gap={6}>
                <FilesSection />
              </Stack>
            </SimpleGrid>
          </CardBody>
        </Card>
      </form>
    </FormProvider>
  )
}

import { Fragment } from 'react'
import { Box, Divider, Flex, Skeleton, Text, useBreakpoint } from 'src/components/designsystem'
import { ReturnArrow } from 'src/components/designsystem/Icons'
import {
  DetailGrid,
  ErrorState,
  FormattedDate,
  FormattedDateTime,
  FormattedTime,
  renderOrEmDash,
  SkeleKeyValue,
} from 'src/components/resource'
import { HTTPError } from 'src/api'
import { useConfig } from 'src/data/config'
import AssociatedContracts from 'src/components/tickets/AssociatedContracts'
import AssociatedSettlements from 'src/components/tickets/AssociatedSettlements'
import { useTicketDetail } from 'src/data/queries'
import { Ticket } from 'src/types/tickets/Ticket'
import { FieldNamesSection } from 'src/components/tickets/field-names/FieldNamesSection'

const SKELETON_REMARKS_ROWS = 5
const SKELETON_SPLITS_ROWS = 1

const SECTION_CONFIG = [
  {
    key: 'details',
    component: DetailSection,
    column: { base: 1, sm: 1, md: 1, lg: 1, xl: 1, '2xl': 1 },
  },
  {
    key: 'field-name',
    component: FieldNamesSection,
    column: { base: 1, sm: 1, md: 1, lg: 1, xl: 1, '2xl': 1 },
  },
  {
    key: 'weights',
    component: WeightsSection,
    column: { base: 1, sm: 1, md: 1, lg: 2, xl: 2, '2xl': 2 },
  },
  {
    key: 'units',
    component: UnitsSection,
    column: { base: 1, sm: 1, md: 1, lg: 2, xl: 2, '2xl': 2 },
  },
  {
    key: 'splits',
    component: SplitsSection,
    column: { base: 1, sm: 1, md: 2, lg: 2, xl: 2, '2xl': 2 },
  },
  {
    key: 'grade-factors',
    component: GradeFactorsSection,
    column: { base: 1, sm: 1, md: 2, lg: 3, xl: 3, '2xl': 3 },
  },
]

function getSectionsForColumn({
  columnIndex,
  breakpoint = 'xl',
  isLoaded,
  ticketDetail,
}: {
  columnIndex: number
  breakpoint?: ChakraBreakpoint
  isLoaded: boolean
  ticketDetail: Ticket
}) {
  return (
    <Fragment>
      {SECTION_CONFIG
        // Only want sections in this column for the current breakpoint
        .filter((section) => section.column[breakpoint] === columnIndex)
        .map(({ key, component: Component }) => (
          <Component key={key} {...{ isLoaded, ticketDetail }} />
        ))}
    </Fragment>
  )
}

interface TicketDetailProps {
  isLoaded?: boolean
  error?: HTTPError
  ticketDetail: Ticket
}

export default function TicketDetail({
  isLoaded = false,
  error = null,
  ticketDetail,
}: TicketDetailProps) {
  const { tickets: ticketsConfig } = useConfig()
  const shouldFetchTicketApplications = ticketsConfig.shouldFetchTicketApplications()

  const { breakpoint } = useBreakpoint()

  if (error) {
    return (
      <ErrorState
        subHeader={
          error?.response?.payload?.error?.message ||
          "There was an error while retrieving this ticket's details. Please refresh the page to try again."
        }
      />
    )
  }

  return (
    <>
      <DetailGrid.Title>Breakdown</DetailGrid.Title>
      <DetailGrid>
        <DetailGrid.List>
          {getSectionsForColumn({ columnIndex: 1, breakpoint, isLoaded, ticketDetail })}
        </DetailGrid.List>
        <DetailGrid.List>
          {getSectionsForColumn({ columnIndex: 2, breakpoint, isLoaded, ticketDetail })}
        </DetailGrid.List>
        <DetailGrid.List>
          {getSectionsForColumn({ columnIndex: 3, breakpoint, isLoaded, ticketDetail })}
        </DetailGrid.List>
      </DetailGrid>

      {ticketDetail?.comments && (
        <>
          <DetailGrid.Title>Comments</DetailGrid.Title>
          <Box layerStyle="detail-card">
            <Skeleton isLoaded={isLoaded}>
              <Text whiteSpace="pre-line">{ticketDetail?.comments}</Text>
            </Skeleton>
          </Box>
        </>
      )}
      {shouldFetchTicketApplications && (
        <>
          <Box mt={[6, null, null, 8]}>
            <AssociatedContracts ticketId={ticketDetail?.id} />
          </Box>
          <Box mt={[6, null, null, 8]}>
            <AssociatedSettlements ticketId={ticketDetail?.id} />
          </Box>
        </>
      )}
    </>
  )
}

function DetailSection({ isLoaded, ticketDetail }) {
  const { tickets: ticketsConfig } = useConfig()
  const hideAccountIdOnTicketDetail = ticketsConfig.hideAccountIdOnTicketDetail()
  const ignoreWeighingTimezones = ticketsConfig.ignoreWeighingTimezone()
  const hideTime = ticketsConfig.hideTime()

  return (
    <DetailGrid.ListCard heading="Details">
      <SkeleKeyValue isLoaded={isLoaded} label={hideTime ? 'Date' : 'Date/Time'}>
        {renderOrEmDash({
          value: ticketDetail?.created_at,
          itemToRender: (
            <>
              {hideTime ? (
                <FormattedDate
                  date={ticketDetail?.created_at}
                  localize={!ignoreWeighingTimezones}
                />
              ) : (
                <FormattedDateTime
                  date={ticketDetail?.created_at}
                  localize={!ignoreWeighingTimezones}
                />
              )}
            </>
          ),
        })}
      </SkeleKeyValue>
      <Divider />
      <SkeleKeyValue isLoaded={isLoaded} label="Location">
        {renderOrEmDash({ value: ticketDetail?.elevator_name })}
      </SkeleKeyValue>
      <Divider />
      {!hideAccountIdOnTicketDetail && (
        <>
          <SkeleKeyValue isLoaded={isLoaded} label="Account ID">
            {renderOrEmDash({ value: ticketDetail?.remote_user_id })}
          </SkeleKeyValue>
          <Divider />
        </>
      )}
      <SkeleKeyValue isLoaded={isLoaded} label="Commodity">
        {renderOrEmDash({ value: ticketDetail?.crop_name })}
      </SkeleKeyValue>
      <Divider />
      {ticketDetail?.addendum?.map((addendum) => (
        <Fragment key={addendum.name}>
          <SkeleKeyValue isLoaded={isLoaded} label={addendum.name}>
            {renderOrEmDash({ value: addendum.value })}
          </SkeleKeyValue>
          <Divider />
        </Fragment>
      ))}
      <SkeleKeyValue isLoaded={isLoaded} label="Driver">
        {renderOrEmDash({ value: ticketDetail?.truck_driver })}
      </SkeleKeyValue>
      <Divider />
      <SkeleKeyValue isLoaded={isLoaded} label="Truck">
        {renderOrEmDash({ value: ticketDetail?.truck_name })}
      </SkeleKeyValue>
      <Divider />
      <SkeleKeyValue isLoaded={isLoaded} label="License">
        {renderOrEmDash({ value: ticketDetail?.truck_license })}
      </SkeleKeyValue>
      <Divider />
      <SkeleKeyValue isLoaded={isLoaded} label="Weigh-in">
        {renderOrEmDash({
          value: ticketDetail?.weigh_in_at,
          itemToRender: (
            <FormattedTime date={ticketDetail?.weigh_in_at} localize={!ignoreWeighingTimezones} />
          ),
        })}
      </SkeleKeyValue>
      <Divider />
      <SkeleKeyValue isLoaded={isLoaded} label="Weigh-out">
        {renderOrEmDash({
          value: ticketDetail?.weigh_out_at,
          itemToRender: (
            <FormattedTime date={ticketDetail?.weigh_out_at} localize={!ignoreWeighingTimezones} />
          ),
        })}
      </SkeleKeyValue>
    </DetailGrid.ListCard>
  )
}

function UnitsSection({ isLoaded, ticketDetail }) {
  return (
    <DetailGrid.ListCard heading="Units">
      <SkeleKeyValue isLoaded={isLoaded} label="Gross Units">
        {renderOrEmDash({
          value: ticketDetail?.gross_amount,
          itemToRender: `${ticketDetail?.gross_amount} ${ticketDetail?.crop_amount_uom}`,
        })}
      </SkeleKeyValue>
      <Divider />
      <SkeleKeyValue isLoaded={isLoaded} label="Shrink Units">
        {renderOrEmDash({
          value: ticketDetail?.shrink_amount,
          itemToRender: `${ticketDetail?.shrink_amount} ${ticketDetail?.crop_amount_uom}`,
        })}
      </SkeleKeyValue>
      <Divider />
      <SkeleKeyValue isLoaded={isLoaded} label="Net Units">
        {renderOrEmDash({
          value: ticketDetail?.net_amount,
          itemToRender: `${ticketDetail?.net_amount} ${ticketDetail?.crop_amount_uom}`,
        })}
      </SkeleKeyValue>
    </DetailGrid.ListCard>
  )
}

function WeightsSection({ isLoaded, ticketDetail }) {
  return (
    <DetailGrid.ListCard heading="Weights">
      <SkeleKeyValue isLoaded={isLoaded} label="Gross Weight">
        {renderOrEmDash({
          value: ticketDetail?.gross_weight,
          itemToRender: `${ticketDetail?.gross_weight} ${ticketDetail?.crop_weight_uom}`,
        })}
      </SkeleKeyValue>
      <Divider />
      <SkeleKeyValue isLoaded={isLoaded} label="Tare Weight">
        {renderOrEmDash({
          value: ticketDetail?.tare_weight,
          itemToRender: `${ticketDetail?.tare_weight} ${ticketDetail?.crop_weight_uom}`,
        })}
      </SkeleKeyValue>
      <Divider />
      <SkeleKeyValue isLoaded={isLoaded} label="Net Weight">
        {renderOrEmDash({
          value: ticketDetail?.net_weight,
          itemToRender: `${ticketDetail?.net_weight} ${ticketDetail?.crop_weight_uom}`,
        })}
      </SkeleKeyValue>
    </DetailGrid.ListCard>
  )
}

function SplitsSection({ isLoaded, ticketDetail }) {
  if (!ticketDetail?.splits || ticketDetail?.splits.length === 0) return null
  return (
    <DetailGrid.ListCard heading="Splits">
      {isLoaded ? (
        ticketDetail?.splits?.map((s, i) => (
          <Fragment key={s?.remote_user_id}>
            <Flex
              w="100%"
              justifyContent="space-between"
              alignItems="center"
              minH={['40px', null, '48px']}
            >
              <Text pr={2} textStyle="body-bold" data-testid="split-owner">
                {s?.remote_user_name || s?.remote_user_id}
              </Text>
              <Text
                textStyle="body-bold"
                aria-label={s?.remote_user_name || s?.remote_user_id}
                data-testid="split-percent"
              >
                {s?.split}
              </Text>
            </Flex>

            {s.contracts?.map((splitContract) => (
              <Flex
                w="100%"
                key={splitContract.contract_id}
                justifyContent="space-between"
                minH={['40px', null, '48px']}
              >
                <Text display="flex" minH={['40px', null, '48px']} alignItems="center">
                  <ReturnArrow
                    color="gray.300"
                    mr={2}
                    minH={['40px', null, '48px']}
                    fontSize="24px"
                  />
                  <Text pr={2} data-testid="split-contract">
                    {splitContract.contract_id}
                  </Text>
                </Text>
                <Box display="flex" minH={['40px', null, '48px']} alignItems="center">
                  <Text
                    aria-label={splitContract.contract_id}
                    whiteSpace="pre"
                    textStyle="body-bold"
                    data-testid="split-contract-amount"
                  >
                    {splitContract.applied_amount}
                  </Text>
                </Box>
              </Flex>
            ))}
            {i < ticketDetail?.splits?.length - 1 && <Divider />}
          </Fragment>
        ))
      ) : (
        <TicketDetailSkeletonRows rows={SKELETON_SPLITS_ROWS} />
      )}
    </DetailGrid.ListCard>
  )
}

function GradeFactorsSection({ isLoaded, ticketDetail }) {
  if (!ticketDetail?.remarks || ticketDetail?.remarks.length === 0) return null
  return (
    <DetailGrid.ListCard heading="Grade Factors">
      {isLoaded ? (
        ticketDetail?.remarks
          ?.sort((a, b) => (a.name > b.name ? 1 : -1))
          .map((remark, i) => (
            <Fragment key={remark.type}>
              <SkeleKeyValue isLoaded={isLoaded} label={remark.name}>
                {remark.value}
              </SkeleKeyValue>
              {remark.dock_unit && remark.dock_value && +remark.dock_value !== 0 && (
                <Flex w="100%" justifyContent="space-between">
                  <Box display="flex" minH={['40px', null, '48px']} alignItems="center">
                    <ReturnArrow color="gray.300" mr={2} fontSize="24px" />
                    <Text>Adjustment</Text>
                  </Box>
                  <Box display="flex" minH={['40px', null, '48px']} alignItems="center">
                    <Text
                      aria-label={`${remark.dock_value} ${remark.dock_unit}`}
                      whiteSpace="pre"
                      textStyle="body-bold"
                    >
                      {remark.dock_value} {remark.dock_unit}
                    </Text>
                  </Box>
                </Flex>
              )}
              {i < ticketDetail?.remarks?.length - 1 && <Divider />}
            </Fragment>
          ))
      ) : (
        <TicketDetailSkeletonRows rows={SKELETON_REMARKS_ROWS} />
      )}
    </DetailGrid.ListCard>
  )
}

function TicketDetailSkeletonRows({ rows }: { rows: number }) {
  return (
    <>
      {[...(Array(rows).keys() as any)].map((i) => (
        <Fragment key={i}>
          <SkeleKeyValue isLoaded={false} label="Remark">
            123
          </SkeleKeyValue>
          {i < rows - 1 && <Divider />}
        </Fragment>
      ))}
    </>
  )
}

export function TicketDetailWithData({ id }: { id: number | string }) {
  const ticketDetailQuery = useTicketDetail({ id, enabled: true })

  return (
    <TicketDetail
      isLoaded={ticketDetailQuery.isSuccess}
      error={ticketDetailQuery.error}
      ticketDetail={ticketDetailQuery.data}
    />
  )
}

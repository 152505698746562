import { useIsMutating } from '@tanstack/react-query'
import { useAuth } from 'src/auth'
import Link from 'next/link'
import { Hide, Show } from '@chakra-ui/react'
import { useFormState } from 'react-hook-form'
import { Button, Heading } from '@bushelpowered/design-system'
import { ButtonGroup, CardHeader, HStack, Spacer } from 'src/components/designsystem'
import BottomButtonBar from 'src/components/admin/crm/bottom-button-bar'
import { STAFF_CUSTOM_PAGES_MUTATION_KEY } from 'src/data/queries/custom-pages'

export default function FormHeader({
  isEditing,
  isPublished,
}: Readonly<{ isEditing: boolean; isPublished: boolean }>) {
  return (
    <CardHeader borderBottom="1px solid" borderColor="gray.300">
      <HStack>
        <Heading type="h4">{isEditing ? 'Edit Page' : 'New Page'}</Heading>
        <Spacer />
        <QuickActionButtons isPublished={isPublished} />
      </HStack>
    </CardHeader>
  )
}

function QuickActionButtons({ isPublished }: Readonly<{ isPublished: boolean }>) {
  const { slug } = useAuth()
  const { isSubmitting } = useFormState()
  const runningMutations = useIsMutating({ mutationKey: STAFF_CUSTOM_PAGES_MUTATION_KEY })

  const submitDisabled = isSubmitting || runningMutations > 0

  // Buttons need to have a ml important to override the default ml in ButtonGroup
  const buttons = (
    <>
      <Button
        as={Link}
        variant="secondary"
        minW="102px"
        w={['full', null, 'unset']}
        href={[null, slug, 'staff', 'custom-pages'].join('/')}
        isDisabled={submitDisabled}
        onClick={(e) => e.stopPropagation()}
        ml="0 !important"
      >
        Cancel
      </Button>
      {isPublished ? (
        <Button
          minW="102px"
          w={['full', null, 'unset']}
          onClick={(e) => e.stopPropagation()}
          isDisabled
          type="submit"
          isLoading={submitDisabled}
          ml="0 !important"
        >
          Update
        </Button>
      ) : (
        <>
          <Button
            minW="102px"
            variant="outline"
            w={['full', null, 'unset']}
            onClick={(e) => e.stopPropagation()}
            type="submit"
            isLoading={submitDisabled}
            ml="0 !important"
          >
            Save
          </Button>
          <Button
            minW="102px"
            w={['full', null, 'unset']}
            onClick={(e) => e.stopPropagation()}
            isDisabled
            isLoading={submitDisabled}
            ml="0 !important"
          >
            Publish
          </Button>
        </>
      )}
    </>
  )

  return (
    <>
      <Show above="sm">
        <ButtonGroup gap={2}>{buttons}</ButtonGroup>
      </Show>
      <Hide above="sm">
        <BottomButtonBar flexDir={isPublished ? undefined : 'column'} gap={2}>
          {buttons}
        </BottomButtonBar>
      </Hide>
    </>
  )
}
